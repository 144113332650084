body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  position: absolute;
}

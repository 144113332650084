body {
  background: #fff;
}

.wide-modal {
  max-width: 600px;
}

.wide-modal p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding-left: 30px;
  padding-right: 30px;
}

.imgstyle {
  max-width: 100%;
  max-height: 100%;
}

.img-toolbar {
  padding: 2px;
  flex-direction: row;
  justify-content: space-around;
}

.img-toolbar-btn-group {
  padding: 2px 2px;
}

.img-toolbar-btn-group .badge {
  color: white;
  background-color: red;
  padding: 0 3px;
  position: relative;
  left: 17px;
  top: -15px;
}

.toolbar {
  background-color: white;
  max-height: 13vh;
  display: inline-block;
  width: 100%;
  padding: 1vh;
}

.toolbar > div > img {
  padding-top: 1vh;
  max-height: 11vh;
  margin-right: 1vw;
}

.toolbar > div > .btn-group {
  margin-bottom: 2vh;
}

.btn-bar {
  height: 13vh;
}

.btn-bar > div > h5 {
  margin-top: 4vh;
}

.btn-label {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-label-set {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}

.btn-label:hover,
.btn-label-set:hover {
  cursor: default;
}

#main-container {
  padding-left: 0;
}

#navbar {
  padding-left: 0;
  padding-right: 0;
}

.imageviewer-toolbar {
  max-height: 12vh;
  background-color: white;
}

.imageviewer-toolbar > div > div > img {
  max-height: 9vh;
}

canvas:focus {
  outline: none;
}

.btn-group-suggest-font-grp > .btn,
.btn-suggest-font-grp {
  padding: 0.2rem 0.2rem;
  font-size: 0.4rem;
  line-height: 0.4rem;
  border-radius: 0.1rem;
}

.btn-group-suggest-font-grp > .btn:focus,
.btn:active:focus {
  background-color: beige;
  box-shadow: none;
}

.btn-group-suggest-font-grp > .btn:focus:hover {
  color: black;
}
